#parcours {
  margin-bottom: 5%;
}

.sliderImg{
  width: auto;
  height: 60vh;
}

.sliderDiv{
  width: auto;
  height: 90vh;
}
