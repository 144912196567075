.BodyStart
{
  height : 100vh;
}

.Accroche
{
  font-weight : bold;
  font-size : 40px;
  width : 1200px;
  letter-spacing : 0.2em;
  text-align : center;
  margin : 0;
  padding : 1em 0;
  font-family : 'Luna';
}

@font-face {
	font-family: "Luna";
	src: url('../../font/Luna.ttf');
}


.roundedImage{
    overflow:hidden;
    border-radius:50%;
    border : 5px solid transparent;
    background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%);
    width:200px;
    height:200px;
    text-align: center;
    margin-bottom : 4%;

}
