html{
  background-image: url("../../images/background.jpg");
}

h1, h2, p{
  font-family: consolas;
}

h1 {
  text-decoration: underline;
}
