a {
  text-decoration: none;
  color: white;
}

.nav {
  list-style-type: none;
  text-align: center;
  padding: 0;
  margin: 0;
  background-color: grey;
}

.nav li{
  display: inline-block;
  font-size: 150%;
  width: 25%;
  margin-bottom: 1%;
  margin-top: 1%
}

.nav img{
  width: 50px;
  height: 50px;
}

.nav a:hover {
  color: black;
}


.social-menu ul {

  position: absolute; /* postulat de départ */
  top: 92%;
  left: 50%; /* à 50%/50% du parent référent */
  margin-bottom : 2%;
  transform: translate(-50%, -50%); /* décalage de 50% de sa propre taille */
    padding: 0;

    display: flex;
}
.social-menu ul li {
    list-style: none;
    margin: 0 10px;
}
.social-menu ul li .fa {
    color: white;
    font-size: 25px;
    line-height: 50px;
    transition: .5s;
}
.social-menu ul li .fa:hover {
    color: #ffffff;
}
.social-menu ul li a {
    position: relative;
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: black;
    text-align: center;
    transition: 0.5s;
    transform: translate(0,0px);
    box-shadow: 0px 7px 5px rgba(0, 0, 0, 0.5);
}
.social-menu ul li a:hover {
    transform: rotate(0deg) skew(0deg) translate(0, -10px);
}
.social-menu ul li:nth-child(1) a:hover {
    background-color: #55acee;
}
.social-menu ul li:nth-child(2) a:hover {
    background-color: #e4405f;
}
.social-menu ul li:nth-child(3) a:hover {
    background-color: #FC6D26;
}
.social-menu ul li:nth-child(4) a:hover {
    background-color: #0077B5;
}
