/* The navigation bar */


.navbar
{
  z-index : 1;
  position : absolute;
}

@font-face {
	font-family: "Luna";
	src: url('../../font/Luna.ttf');
}

/* Links inside the navbar */
#navbar a {

  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding-bottom: 1%;
  padding-top: 1%;
  text-decoration: none;
  width: 25%;
}

/* Change background on mouse-over */
#navbar a:hover {
  background: #ddd;
  color: black;
}

/* Main content */
#main {
  margin-top: 30px; /* Add a top margin to avoid content overlay */
}
